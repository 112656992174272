@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind froms;

html,
body,
#root {
  height: 100vh;
}

[data-theme='light'] {
  --gray1: #fbfcfd;
  --gray2: #f8f8f8;
  --gray3: #f1f3f5;
  --gray4: #e6e8eb;
  --gray5: #e6e8eb;
  --gray6: #e2e2e2;
  --gray7: #d7dbdf;
  --gray8: #c7c7c7;
  --gray9: #8f8f8f;
  --gray10: #7e868c;
  --gray11: #6f6f6f;
  --gray12: #171717;

  --elevation1: #f1f3f5;
  --elevation2: #f8f9fa;
  --elevation3: #fbfcfd;

  --brightness: 0;

  --shadow-sm: 0px 0px 0px 2px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 8px 24px -5px rgb(0 0 0 / 9%);
  --shine-border: conic-gradient(#00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080);

  --success11: #147d6f;
  --success9: #70e1c8;
  --success3: #e1fbf4;

  --error11: #ca3214;
  --error9: #e54d2e;
  --error3: #fff0ee;
}

[data-theme='dark'] {
  --gray1: #161616;
  --gray2: #1c1c1c;
  --gray3: #232323;
  --gray4: #282828;
  --gray5: #2e2e2e;
  --gray6: #343434;
  --gray7: #3e3e3e;
  --gray8: #505050;
  --gray9: #707070;
  --gray10: #7e7e7e;
  --gray11: #a0a0a0;
  --gray12: #ededed;

  --elevation1: #1c1c1c;
  --elevation2: #161616;
  --elevation3: #232323;

  --brightness: 100;

  --shadow-sm: 0px 0px 0px 2px rgba(255, 255, 255, 0.25);
  --shadow-lg: 0px 24px 16px -16px rgba(0, 0, 0, 0.25);
  --shine-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);

  --success11: #25d0ab;
  --success9: #70e1c8;
  --success3: #052926;

  --error11: #f16a50;
  --error9: #e54d2e;
  --error3: #3b1813;
}

.rs-btn-primary:focus,
.rs-btn-primary {
  background-color: var(--rs-btn-primary-hover-bg) !important;
}

.rs-picker-daterange-panel .rs-calendar {
  display: block;
  margin: 0 auto;
  min-width: 110px;
}
.rs-picker-daterange-calendar-group {
  min-width: 100px;
  display: flex;
}
.rs-picker-daterange-panel .rs-calendar .rs-calendar-time-dropdown-column > ul {
  height: 125px;
  padding-bottom: 0;
}

.active .foodollar-icon {
  filter: var(--tw-blur) brightness(var(--brightness)) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
