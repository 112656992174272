/* ChartContainer component CSS */

.chartContainer {
  border: solid 1.5px var(--gray7);
  box-shadow: var(--shadow-lg);
  border-radius: 1rem;
  overflow: hidden;
  isolation: isolate;
  background: var(--gray1);
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
}

.chartContainer li {
  font-size: 0.875rem;
  overflow: hidden;
}

.chartContainerInsight {
  isolation: isolate;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  margin-left: -25px;
  z-index: auto;
}

.chartContainerInsight li {
  font-size: 0.875rem;
  overflow: hidden;
}

.chartWrapper {
  position: relative;
}
/* Loading state component CSS */

.shinyBoxContainer {
  padding: 2px;
  overflow: hidden;
  width: fit-content;
  height: 3.5rem;
  border-radius: 99rem;
  /*box-shadow: 0px 11px 8px -4px rgba(0,0,0,0.16);*/
  isolation: isolate; /* required on Safari, as `overflow: hidden` does not impose border radius */
  margin-top: -3rem;
}

.shinyBox {
  position: relative;
  height: 100%;
  width: 100%;
  background: linear-gradient(var(--gray3), var(--gray2));
  color: var(--gray12);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99rem;
  padding: 1rem 1.5rem;
  background-clip: padding-box; /* excludes border from background */
  font-size: 1rem;
}

.shinyBox svg {
  height: 2rem;
}

.shinyBox::before {
  content: '';
  position: absolute;
  height: 800%;
  width: 800%;
  background: var(--shine-border);
  z-index: -1;
  animation: rotate 0.5s infinite linear;
}

.errorBox {
  position: relative;
  height: 100%;
  width: 100%;
  background: linear-gradient(var(--gray3), var(--gray2));
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99rem;
  background-clip: padding-box; /* excludes border from background */
  font-size: 1rem;
  color: var(--error11);
  padding: 1rem 1.5rem;
  text-align: center;
  cursor: pointer;
}

.errorBox svg {
  height: 2rem;
  color: var(--error9);
}

.errorBox::before {
  content: '';
  position: absolute;
  height: 800%;
  width: 800%;
  background: var(--error9);
  z-index: -1;
  animation: rotate 0.5s infinite linear;
}

.loadingContainer {
  display: flex;
  width: 100%;
  position: absolute;
  /*max-width: min(calc(100% - 4.26rem), 70.75rem);*/
  backdrop-filter: blur(4px);
  background: #ffffff01;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

/* ChartNavigation component CSS */

.imageContainer {
  width: 100%;
  padding-top: 43%;

  position: relative;
  border-radius: 1.5rem;
  overflow: hidden;
  margin: 1rem auto 2rem;
  border: solid 1px var(--gray8);
  box-shadow: var(--shadow-lg);
}

.divider {
  width: 100%;
  border: solid 0.5px var(--gray7);
  margin: 1rem 0;
}

.subHeading {
  color: var(--gray11);
  margin-top: 0.5rem;
}

.chartNav {
  display: flex;
  flex-direction: row;
  flex-grow: 5;
  margin-right: -0.425rem;
  z-index: 2;
}

.chartNavButton {
  display: flex;
  gap: 0.375rem;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
  cursor: pointer;
  height: 100%;
  background: var(--elevation2);
  border-bottom: 1px solid var(--gray7);
  border-right: 1px solid var(--gray7);
  transition: 0.3s;
}

.chartNavButton:hover {
  background: var(--gray2);
  border-color: var(--gray7);
}

.chartNavButtonSelected {
  background: var(--elevation3);
}

.chartNavButtonSelected:hover {
  background: var(--elevation3);
  cursor: default;
}

.chartNavButtonWrapper {
  width: fit-content;
}

button .chartNavButtonWrapper {
  all: unset;
}

.chartNavButton:active {
  background: var(--elevation3);
}

.chartNavButtonFullWidth {
  width: 100%;
}

.chartNavButtonUnderline {
  height: 3px;
  width: 100%;
  background: var(--gray12);
  background: #2094ff;
  border-radius: 3rem;
  margin-top: -0.25rem;
}

.chartNavLabel {
  margin: 0;
  font-size: 1rem;
  line-height: 125%;
  color: var(--gray11);
}

.chartNavMetric {
  margin: 0;
  font-size: 2.25rem;
  font-weight: 500;
  letter-spacing: -0.03em;
  color: var(--gray12);
  min-width: 10rem;
}

.trendBadge {
  border-radius: 99rem;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.125rem;
  font-weight: 500;
  color: var(--gray9);
  background: var(--gray3);
}

.smallTrendBadge {
  transform: scale(0.75);
}

.greenBadge {
  color: var(--success11);
  border: solid 1px var(--success9);
  background: var(--success3);
}

.redBadge {
  background: var(--error3);
  color: var(--error11);
  border: solid 1px var(--error9);
}

.metricContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.modifiersContainer {
  display: flex;
  gap: 0.5rem;
  max-width: 100%;
  width: fit-content;
  flex-direction: column;
  padding: 1rem 1rem 0;
  height: 100%;
  align-items: flex-end;
  flex-wrap: wrap;
}

.topBar {
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-right: -1px;
}

.dateTimeRangeButton {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--gray11);
  background: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  width: fit-content;
  padding: 0.375rem 0.5rem;
  border: solid 1px transparent;
  transition: 0.2s;
}

.dateTimeRangeButton > svg {
  color: var(--gray9);
}

.dateTimeRangeButton:hover > svg {
  color: var(--gray12);
}

.dateTimeRangeButton:hover {
  /*background: #E6E8EB;*/
  color: var(--gray12);
  transition: 0.1s;
}

.dateTimeRangeButton:active {
  background: var(--gray1);
  transition: 0.1s;
}

.dateTimeRangeButton:focus {
  border-color: #2094ff;
  background: var(--gray1);
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media (max-width: 800px) {
  .topBar {
    flex-direction: column-reverse;
  }
  .modifiersContainer {
    max-width: none;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: solid 1px var(--gray7);
  }
}

@media (max-width: 480px) {
  .modifiersContainer {
    max-width: none;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 0.5rem;
    border-bottom: solid 1px var(--gray7);
    align-items: center;
  }
  .chartNavLabel {
    font-size: 0.875rem;
  }
  .metricContainer {
    flex-direction: column;
  }
  .chartNavButton {
    align-items: center;
  }
  .chartNavButtonWrapper {
    width: 100%;
  }
  .chartNavMetric {
    text-align: center;
  }
  .metricContainer {
    gap: 0.5rem;
  }
}

.rechartsLegendStyle ul {
  font-size: 0.75rem !important;
  margin-left: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.legendSquare {
  width: 20px;
  height: 12px;
  border-radius: 4px;
  display: none;
}

.legendLine {
  height: 3px;
  width: 20px;
  border-radius: 4px;
  display: none;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}
